import QrCode from '@/components/QrCode/index.vue';

const components = [QrCode];

const install = function (Vue, opts = {}) {
  components.forEach((component) => {
    Vue.component(component.name, component);
  });

  Vue.prototype.$VP = {
    size: opts.size || '',
    zIndex: opts.zIndex || 2000,
  };
};

export default {
  install,
  QrCode,
};
