<template>
  <div :style="{ width: width + 'px' }">
    <canvas :id="id"></canvas>
    <div v-if="showContent" :style="{ width: width + 'px', textAlign: 'center', fontSize: '14px' }">
      {{ labelValue }}
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  name: 'QrCode',
  props: {
    // 二维码值
    value: {
      type: String,
      required: true,
    },
    // id
    id: {
      type: String,
      default: () => {
        return Math.random() * 10000 + '';
      },
    },
    // 纠错级别
    errorCorrectionLevel: {
      type: String,
      default: 'M',
    },
    // 大小
    width: {
      type: Number,
      default: 80,
    },
    // 边距
    margin: {
      type: Number,
      default: 1,
    },
    // 二维码颜色
    color: {
      type: String,
      default: '#000000ff',
    },
    // 周边颜色
    light: {
      type: String,
      default: '#ffffffff',
    },
    // 底部显示二维码内容
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelValue: '',
    };
  },
  watch: {
    // 值，宽度变化，重新渲染
    value: {
      handler() {
        console.log(123);
        this.labelValue = this.value;
        this.initCreateQrcode();
      },
      immediate: true,
      deep: true,
    },
    width: {
      handler() {
        console.log(123);
        this.initCreateQrcode();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 初始化创建二维码
    // errorCorrectionLevel纠错级别 默认H ，汉字可以到700多个，基本够用的
    initCreateQrcode() {
      this.$nextTick(() => {
        let option = {
          errorCorrectionLevel: this.errorCorrectionLevel,
          width: this.width,
          margin: this.margin,
          color: {
            dark: this.color,
            light: this.light,
          },
        };
        var canvas = document.getElementById(this.id);
        QRCode.toCanvas(canvas, this.value, option, function (error) {
          if (error) {
            console.error(error);
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
